<template>
  <div class="card mx-4">
    <div class="contact_row d-flex justify-center align-center pt-4">
      {{ accountName }} <span class="px-1">({{ accountNumber }})</span>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div>
        <span class="mb-0 text-center row__text" style="">{{
          balance | currency(currencyWithShortCode)
        }}</span>
      </div>
      <div>
        <span class="mb-0 row__text">{{ account }}</span>
      </div>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div>
        <v-chip class="pa-1 text-center" x-small dark :color="statusColor"
          ><span class="px-1 ma-0" style="font-size: 10px">{{
            type
          }}</span></v-chip
        >
      </div>
      <div>
        <span class="mb-0 row__text">{{ lastUpdated }}</span>
      </div>
      <div class="d-flex align-center">
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="$emit('view-Balance')"
            :style="{
              'background-color': hover ? '#2bd5ae' : '',
              border: hover ? 'none' : '',
            }"
            exact-path
            depressed
            outlined
            dark
            small
            color="#2BD5AE"
            class="text-capitalize px-1 my-1 mr-1 rounded"
            style="color: var(--v-primary-base)"
          >
            Get Balance
          </v-btn>
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
import currency from "@/mixins/currencyFilter.js";
export default {
  name: "accountDataTableRowCard",
  mixins: [currency],
  props: {
    balance: {
      type: [String, Number],
    },
    index: {
      type: Number,
    },
    type: {
      type: String,
    },

    account: {
      type: String,
    },
    accountName: {
      type: String,
    },
    accountNumber: {
      type: String,
    },

    lastUpdated: {
      type: String,
    },
  },
  computed: {
    statusColor() {
      return this.type === "internal" ? "#807f80" : "#27779c";
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 200px;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .contact_row {
    width: 100%;
    font-weight: 600;
  }
}

.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}
</style>
