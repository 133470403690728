<template>
  <div>
    <div class="loader" v-if="isLoadingDetails">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="px-2 px-sm-6 px-md-8 py-8 drawer__container" v-else>
      <v-form ref="postTransactionForm">
        <div>
          <p class="drawer_title">Bank Transaction Posting</p>
          <p class="drawer_info py-2">
            Manually post a transaction expected to happen in your bank account,
            this is useful for reconciliation.
          </p>
        </div>
        <div class="py-1">
          <label>Select an Account</label>
          <v-select
            :items="filteredAccountArray"
            item-value="hypn_id"
            item-text="account"
            :menu-props="{ searchable: true, lazy: true }"
            prepend-inner-icon="mdi-magnify"
            :hide-details="true"
            outlined
            v-model="postTransactionObject.bank"
            class="hypn-input"
            :rules="[(value) => !!value || 'select an option']"
          >
            <template #item="{ item }">
              <div class="item-wrapper py-1">
                <span>{{ item.account }}</span>
                <small class="grey--text text--lighten-1">{{
                  item.bank
                }}</small>
              </div>
            </template>
          </v-select>
          <p class="txt_btn" @click="handleAddBankModal">+ add Bank Account</p>
        </div>
        <div class="py-1">
          <label>Money Direction</label>
          <v-select
            :items="moneyDirection"
            item-text="text"
            item-value="value"
            v-model="postTransactionObject.direction"
            :hide-details="true"
            outlined
            :rules="[(value) => !!value || 'select an option']"
          >
          </v-select>
        </div>
        <div class="py-1">
          <label>Payment Type</label>
          <v-select
            :items="paymentType"
            item-text="text"
            item-value="value"
            v-model="postTransactionObject.type"
            :hide-details="true"
            outlined
            :rules="[(value) => !!value || 'select an option']"
          >
          </v-select>
        </div>
        <div class="py-1">
          <label>Counter-party</label>
          <v-select
            :items="filteredCustomersAndVendors"
            prepend-inner-icon="mdi-magnify"
            item-value="id"
            item-text="company"
            :menu-props="{ searchable: true, lazy: true }"
            :hide-details="true"
            outlined
            v-model="postTransactionObject.entity"
            @change="updateEntityType"
            :rules="[(value) => !!value || 'select an option']"
          >
          </v-select>
          <div class="d-flex align-center justify-space-between">
            <p class="txt_btn" @click="handleAddCustomer">+ add customer</p>
            <p class="txt_btn" @click="handleInviteVendor">+ invite vendor</p>
          </div>
        </div>
        <div class="py-2">
          <hr class="dottedLine" />
        </div>
        <div class="py-1" v-if="postTransactionObject.direction === 'debit'">
          <label>Select Payable</label>
          <v-select
            :items="filteredPayableArr"
            prepend-inner-icon="mdi-magnify"
            item-value="id"
            item-text="vendor"
            :menu-props="{ searchable: true, lazy: true }"
            :hide-details="true"
            outlined
            v-model="postTransactionObject.tranx"
            :rules="[(value) => !!value || 'select an option']"
          >
            <template #item="{ item }">
              <div class="item-wrapper py-2">
                <span>{{ item.vendor }}</span>
                <small class="grey--text text--lighten-1 text-truncate-left">{{
                  item.ref
                }}</small>
              </div>
            </template>
          </v-select>
        </div>
        <div class="py-1" v-if="postTransactionObject.direction === 'credit'">
          <label>Select Receivable</label>
          <v-select
            :items="filteredReceivableArr"
            prepend-inner-icon="mdi-magnify"
            item-value="id"
            item-text="amount"
            :menu-props="{ searchable: true, lazy: true }"
            :hide-details="true"
            outlined
            v-model="postTransactionObject.tranx"
            :rules="[(value) => !!value || 'select an option']"
          >
            <template #item="{ item }">
              <div class="item-wrapper py-2">
                <span class="font-weight-bold">{{
                  item.amount | currency()
                }}</span>
                <small class="grey--text text--lighten-1 text-truncate-left">{{
                  item.ref
                }}</small>
              </div>
            </template>
          </v-select>
        </div>
        <div class="py-1">
          <label>Exact Amount</label>
          <v-text-field
            v-model="postTransactionObject.amount"
            hide-details="auto"
            outlined
            placeholder="0.00"
            @blur="formatAmount"
            :rules="[
              (value) => !!value || 'Amount is required',
              (value) =>
                /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
                'Enter a valid amount',
              (value) => parseFloat(value) > 0 || 'Amount can not be 0',
            ]"
          >
          </v-text-field>
        </div>

        <div class="py-1">
          <label>Narration</label>
          <v-text-field
            v-model="postTransactionObject.narration"
            hide-details="auto"
            outlined
            class="hypn-input"
            :rules="[(value) => !!value || 'Narration is required']"
          >
          </v-text-field>
        </div>
        <v-row>
          <v-col cols="6" class="px-0 ml-3">
            <v-menu
              ref="transMenu"
              v-model="transMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              bottom
              offset-overflow
              min-width="auto"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  v-model="postTransactionObject.date"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Value is required']"
                  v-bind="attrs"
                  outlined
                  v-on="on"
                  class="text__input px-0 hypn-input"
                  persistent-hint
                  style="margin-top: 16px"
                >
                  <template>
                    <v-icon
                      small
                      class="input__icon ml-0"
                      slot="prepend-inner"
                      color="grey lighten-1"
                    >
                      mdi-calendar-month-outline
                    </v-icon>
                  </template>
                  <template #label> Trnx Date </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="postTransactionObject.date"
                :hide-details="true"
                outlined
                no-title
                @input="transMenu = false"
                color="primary"
                :max="getTodayDate()"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div class="py-2">
          <hr class="dottedLine" />
        </div>
        <div class="py-1">
          <p class="section_title">Journal Entry</p>
          <v-row class="py-2 d-flex">
            <v-col>
              <label>Post Account</label>
              <v-select
                :items="organizationInternalAccounts"
                item-value="hypn_id"
                item-text="account"
                v-model="postTransactionObject.to"
                hide-details="auto"
                outlined
                :rules="[(value) => !!value || 'select an option']"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <div class="py-2">
          <label>Tax Deduction?</label>
          <v-select
            :items="taxdeductionOptions"
            item-value="value"
            item-text="text"
            v-model="taxdeduction"
            hide-details="auto"
            outlined
            :rules="[(value) => !!value || 'select an option']"
          >
          </v-select>
        </div>
        <div v-if="taxdeduction === 'yes'" class="py-1">
          <label>Deduction Amount</label>
          <v-text-field
            v-model="postTransactionObject.deduction"
            hide-details="auto"
            placeholder="0.00"
            outlined
            class="hypn-input"
            @blur="formatDeductedAmount"
            :rules="[
              (value) => !!value || 'Amount is required',
              (value) =>
                /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
                'Enter a valid amount',
              (value) => parseFloat(value) > 0 || 'Amount can not be 0',
            ]"
          >
          </v-text-field>
        </div>
        <div class="py-5">
          <v-btn
            :color="disablePost ? '#d9dee1' : 'primary'"
            class="text-capitalize py-md-6 px-md-8"
            :disabled="disablePost"
            :style="
              disablePost ? '' : 'box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)'
            "
            @click="handlePostTransaction"
          >
            <v-icon> mdi-chevron-right </v-icon>
            <span class="ml-2">Post Transaction</span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <AddBankModal ref="AddBankDialog" @clicked="launchMono" />
    <AddVendor ref="AddVendor" />
    <AddCustomer ref="AddCustomer" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddBankModal from "@/components/addBankModal.vue";
import AddVendor from "@/includes/overlays/ContactsMenuModals/AddVendor.vue";
import AddCustomer from "@/includes/overlays/ContactsMenuModals/AddCustomer.vue";
// Import the utility function
import { formatCurrencyAmount } from "@/mixins/amountFormatter.js";

export default {
  data() {
    return {
      isLoadingDetails: false,
      moneyDirection: [
        { text: "Receiving Money (Credit)", value: "credit" },
        { text: "Sending Money (Debit)", value: "debit" },
      ],
      paymentType: [
        { text: "Cheque", value: "cheque" },
        { text: "Transfer", value: "transfer" },
        { text: "Settlement", value: "settlement" },
        { text: "Refund", value: "refund" },
      ],

      transMenu: false,
      // TransactionDate: null,
      transactionFile: null,
      disablePost: false,
      postTransactionObject: {
        direction: "debit",
        type: "transfer",
        narration: "",
        bank: "",
        entity: "",
        entity_type: "",
        to: "",
        amount: (0).toFixed(2),
        tranx: null,
        date: null,
        deduction: (0).toFixed(2),
      },
      taxdeductionOptions: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      taxdeduction: null,
    };
  },
  computed: {
    ...mapGetters({
      organizationBankAccounts: "organizations/getOrganizationBankAccounts",
      organizationInternalAccounts: "organizations/getAccountObjectForInternal",
      filteredAccountArray: "organizations/getAccountObject",
      vendorCounterParty: "contacts/getVendorsCompanyName",
      customerCounterParty: "contacts/getCustomersCompanyName",
      organizationId: "auth/organizationId",
      organizationToken: "organizations/OrganToken",
      filteredPayableArr: "payables/getPayablesTranxArray",
      filteredReceivableArr: "receivables/getReceivablesTranxArray",
    }),
    // // filter the counterParty array using the search value..
    filteredCustomersAndVendors() {
      if (this.postTransactionObject.direction === "credit") {
        let unSorted = this.customerCounterParty
          ?.filter((item) => item.company !== null)
          .sort();
        return unSorted.sort((a, b) => a.company.localeCompare(b.company));
      } else {
        let unSorted = this.vendorCounterParty
          ?.filter((item) => item.company !== null)
          .sort();
        return unSorted.sort((a, b) => a.company.localeCompare(b.company));
      }
    },

    formattedAmount() {
      return formatCurrencyAmount(this.postTransactionObject.amount);
    },
  },
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    formatAmount() {
      this.postTransactionObject.amount = formatCurrencyAmount(
        this.postTransactionObject.amount
      );
    },

    formatDeductedAmount() {
      this.postTransactionObject.deduction = formatCurrencyAmount(
        this.postTransactionObject.deduction
      );
    },
    // get the entity-type
    updateEntityType() {
      const selectedEntity = this.filteredCustomersAndVendors.find(
        (item) => item.id === this.postTransactionObject.entity
      );
      if (selectedEntity) {
        this.postTransactionObject.entity_type = selectedEntity.entity_type;
      } else {
        this.postTransactionObject.entity_type = "";
      }
    },

    // set max date

    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    triggerFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    async handlePostTransaction() {
      if (this.$refs.postTransactionForm.validate()) {
        // Remove commas and periods from the amount string
        const floatAmount = this.postTransactionObject.amount.replace(/,/g, "");
        const floatAmountDeducted =
          this.postTransactionObject.deduction.replace(/,/g, "");

        // Convert the cleaned amount string to an integer
        this.postTransactionObject.amount = parseFloat(floatAmount);
        this.postTransactionObject.deduction = parseFloat(floatAmountDeducted);

        // const formData = new FormData();
        // formData.append('files.meta', this.transactionFile);
        // formData.append('data', JSON.stringify(this.postTransactionObject));

        // console.log(JSON.stringify(this.postTransactionObject, null, 2));

        const postData = {
          organizationId: this.organizationId,
          payload: this.postTransactionObject,
        };

        try {
          await this.$store.dispatch(
            "transactions/postABankTransactions",
            postData
          );

          // Display a success toast message if the dispatch is successful
          this.showToast({
            sclass: "success",
            show: true,
            message: "Transaction posted successfully!",
            timeout: 3000,
          });

          // disable the post btn
          this.disablePost = true;
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        } finally {
          const organHypnId = await this.organizationToken?.data?.hypn_id;

          const dataObject = {
            organization_hypnID: organHypnId,
            start: 0,
            limit: -1,
          };
          this.$store.dispatch("transactions/getOrgTransactions", dataObject);
        }
      }
    },
    // open the add a bank modal
    async handleAddBankModal() {
      await this.$refs.AddBankDialog.fetchAllBanks();
      this.$refs.AddBankDialog.show(true);
    },

    // call the add a customer logic...
    handleAddCustomer() {
      this.$refs.AddCustomer.show(true);
    },

    // call the invite a vendor logic...
    handleInviteVendor() {
      this.$refs.AddVendor.show(true);
    },
    //method to launch the Mono app
    launchMono() {
      const options = {
        onSuccess: (response) => {
          this.submitBankCode(response);
          this.$store.dispatch("organizations/fetchBanks");
        },
        onClose: function () {
          console.log("user closed the widget.");
        },
      };
      this.$launchMono(options);
    },
  },
  mounted() {
    this.isLoadingDetails = true;
    setTimeout(() => {
      this.isLoadingDetails = false;
    }, 3000);
  },
  components: {
    AddBankModal,
    AddVendor,
    AddCustomer,
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.drawer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.drawer_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.dottedLine {
  border: 1px dashed rgba(25, 40, 61, 0.5);
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

#clickArea {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.docTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.filename {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.loader {
  display: flex;
  height: 100vh;
  width: auto;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.section_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.text-truncate-left {
  width: 45px;
  overflow: hidden;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.drawer__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}
</style>
