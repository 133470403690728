<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6 my-1"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center px-4">
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 text-center bold__text" style="">{{
                balance | currency(currencyNoSymbol)
              }}</span>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex align-center">
              <span class="mb-0 text-center row__text text-truncate" style="">{{
                account
              }}</span>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <v-chip
                class="pa-1 text-center"
                x-small
                text-color="#3B4E62"
                dark
                :color="statusColor"
                ><span
                  class="px-2 py-1 ma-0 text-uppercase font-weight-bold"
                  style="font-size: 10px"
                  >{{ type }}</span
                ></v-chip
              >
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text text-truncate"
                >{{ accountName }} <span class="px-1">{{ accountNumber }}</span>
              </span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 text-truncate row__text">{{
                lastUpdated
              }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="$emit('view-Balance')"
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  exact-path
                  depressed
                  outlined
                  dark
                  small
                  color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded"
                  style="color: var(--v-primary-base)"
                >
                  Get Balance
                </v-btn>
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import currency from "@/mixins/currencyFilter.js";

export default {
  name: "accountDataTableRow",
  props: {
    balance: {
      type: [String, Number],
    },
    index: {
      type: Number,
    },
    type: {
      type: String,
    },

    account: {
      type: String,
    },
    accountName: {
      type: String,
    },
    accountNumber: {
      type: String,
    },

    lastUpdated: {
      type: String,
    },
  },
  data() {
    return {};
  },
  mixins: [currency],
  methods: {},
  computed: {
    statusColor() {
      switch (this.type) {
        case "internal":
          return "#DEE4F0";
        case "credit":
          return "#feebea";
        default:
          return "#C2E8F5";
      }
    },
  },
};
</script>

<style scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.bold__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}
</style>
