import { render, staticRenderFns } from "./paymentsDataPage.vue?vue&type=template&id=fe6b176c&scoped=true&"
import script from "./paymentsDataPage.vue?vue&type=script&lang=js&"
export * from "./paymentsDataPage.vue?vue&type=script&lang=js&"
import style0 from "./paymentsDataPage.vue?vue&type=style&index=0&id=fe6b176c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe6b176c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VContainer,VFlex,VLayout,VNavigationDrawer,VProgressCircular,VSkeletonLoader})
