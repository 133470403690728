<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6 my-1"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center px-1">
          <v-flex md3>
            <div class="d-flex align-center">
              <span class="mb-0 text-center row__text" style=""
                >{{ dateFrom }} - {{ dateTo }}</span
              >
            </div>
          </v-flex>
          <v-flex md4>
            <div class="d-flex align-center">
              <v-chip
                color="#EEFCF9"
                text-color="#2BD5AE"
                x-small
                class="mr-2 chip__text"
                >{{ accounts }}</v-chip
              >
              <v-chip
                color="#FDF9EF"
                text-color="#E3AA1C"
                x-small
                class="ml-2 chip__text"
                >{{ accountSettlement }}</v-chip
              >
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ reconciledAmount }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ difference }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <span class="mb-0 text-truncate link__text"
                ><a href="#" download>{{ report }}</a></span
              >
            </div>
          </v-flex>
          <!-- <v-flex md1>
            <div class="d-flex align-center">
              <v-icon left :color="statusIcon" class="ma-0 px-0"
                >mdi-circle-small</v-icon
              >
              <span class="mb-0 row__text pr-3">{{ status }}</span>
            </div>
          </v-flex> -->
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "accountDataTableRow",
  props: {
    dateFrom: {
      type: String,
    },
    dateTo: {
      type: String,
    },
    index: {
      type: Number,
    },
    accounts: {
      type: String,
    },

    accountSettlement: {
      type: String,
    },
    reconciledAmount: {
      type: String,
    },
    difference: {
      type: [String, Number],
      default: 0,
    },

    status: {
      type: String,
    },

    report: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    statusIcon() {
      if (this.status == "saved") {
        return "#7F919B";
      } else if (this.status == "running") {
        return "#E3AA1C";
      } else {
        return "#2BD5AE";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}
.v-application a {
  color: #5b67ba;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
}
</style>
