<template>
  <div class="drawer__container">
    <div class="loader" v-if="isLoadingDetails">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="px-2 px-sm-6 px-md-8 py-8 drawer__form" v-else>
      <div>
        <p class="drawer_title">Send/Transfer Money</p>
        <p class="drawer_info py-2">
          Choose from one of your saved counter-party to make a transfer or
          enter details of a new beneficiary bank account
        </p>
      </div>
      <!-- <pre>{{ appCurrency }}</pre> -->
      <div
        class="
          d-flex
          flex-column
          justify-center
          align-end
          mt-2
          px-3
          py-5
          summaryContainer
        "
      >
        <p class="summaryCardTitle">Payout Balance (?)</p>
        <p class="walletBalance">
          {{ getWalletBalance | currency(currencyDefault) }}
        </p>
      </div>
      <v-form ref="sendReceive" v-if="sendPaymentPayload === null">
        <div class="py-1 mt-2">
          <label>Recipient</label>
          <v-select
            :items="payoutRecipients"
            prepend-inner-icon="mdi-magnify"
            item-value="id"
            item-text="accountname"
            :return-object="true"
            :menu-props="{ searchable: true, lazy: true }"
            hide-details="auto"
            outlined
            v-model="sendMoney.beneficiary"
            :rules="[rules.required]"
          >
          </v-select>
          <div class="d-flex align-center justify-end">
            <p class="txt_btn" @click="handleAddRecipient">+ new recipient</p>
          </div>
        </div>
        <div class="py-1">
          <label>Select payable for this transfer</label>
          <v-select
            v-model="selectedPayable"
            :items="payablesArray"
            item-value="reference"
            item-text="reference"
            :return-object="true"
            prepend-inner-icon="mdi-magnify"
            :menu-props="{ searchable: true, lazy: true }"
            hide-details="auto"
            outlined
          >
            <template #item="{ item }">
              <div class="d-block py-3 px-2">
                <p class="superTxt">{{ item.amount }}</p>
                <p class="mainTxt py-2">{{ item.reference }}</p>
                <p class="subTxt">{{ item.contact }}</p>
              </div>
            </template>
          </v-select>
        </div>

        <div class="py-2">
          <label>
            If no payable is selected for this transfer, an expense payable will
            automatically be generated in payables.
          </label>
          <v-switch v-model="payableIncluded" hide-details="auto" class="ma-0">
            <template v-slot:label>
              <label>Yes, transfer without payable</label>
            </template>
          </v-switch>
        </div>
        <div class="py-1">
          <label>Amount to send?</label>
          <v-text-field
            ref="amountfield"
            v-model="sendMoney.amount"
            hide-details="auto"
            outlined
            placeholder="0.00"
            @blur="formatAmount"
            :rules="[
              (value) => !!value || 'Amount is required',
              (value) =>
                /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
                'Enter a valid amount',
              (value) => {
                if (
                  (selectedPayable && selectedPayable.amount !== undefined) ||
                  !payableIncluded
                ) {
                  const maxValue = parseFloat(selectedPayable.amount);
                  if (parseFloat(value) <= maxValue) {
                    return true;
                  } else {
                    return `Amount should not exceed ${maxValue} expected amount`;
                  }
                } else {
                  return true;
                }
              },
              (value) => parseFloat(value) > 0 || 'Amount can not be 0',
            ]"
          >
          </v-text-field>
        </div>
        <div class="py-1">
          <label>Transaction narration</label>
          <v-text-field
            v-model="sendMoney.narration"
            hide-details="auto"
            placeholder="Enter description"
            outlined
            class="hypn-input"
            :rules="[rules.required]"
          >
          </v-text-field>
        </div>
        <div class="pt-6 pb-2">
          <!-- <hr class="dottedLine" /> -->
        </div>
        <!-- <div class="pt-1 pb-4">
          <p class="section_title">Journal Entry</p>
          <v-row class="py-2">
            <v-col>
              <label>To ledger</label>
              <v-select :items="organizationInternalAccounts" item-value="hypn_id" item-text="account"
                v-model="sendMoney.from" hide-details="auto" :return-object="true" outlined>
              </v-select>
            </v-col>
          </v-row>
        </div> -->
        <div class="py-5 button__container d-flex align-center justify-end">
          <v-btn
            @click="handleCancelDrawer"
            dark
            color="#F4F5F6"
            elevation="0"
            class="text-capitalize py-md-6 px-md-6"
          >
            <v-icon color="primary">mdi-close</v-icon>
            <span class="primary--text">Cancel</span>
          </v-btn>
          <v-btn
            :color="disablePost ? '#d9dee1' : 'primary'"
            class="text-capitalize py-md-6 px-md-6 ml-3"
            :loading="isSending"
            :disabled="disablePost"
            @click="handleSendMoney"
          >
            <v-icon> mdi-chevron-right </v-icon>
            <span class="ml-2">Send</span>
          </v-btn>
        </div>
      </v-form>
      <v-form ref="sendOTP" v-else>
        <div class="py-8">
          <!-- <hr class="dottedLine" /> -->
        </div>
        <p class="sectionHeader standardText">Review your payment</p>
        <div class="my-6">
          <div
            class="paymentDetailsWrapper rounded-t-lg"
            style="
              border-bottom: 1px dotted rgba(238, 240, 241, 1);
              border-top: 1px solid rgba(238, 240, 241, 1);
            "
          >
            <div class="pa-6">
              <small>you are sending</small>
              <!-- <p class="walletBalance py-2">1,300,000</p> -->
              <p v-if="sendPaymentPayload" class="walletBalance">
                {{ sendPaymentPayload.amount | currency(currencyDefault) }}
              </p>
              <small>Naira</small>
            </div>
          </div>
          <div
            class="paymentDetailsWrapper"
            style="border-bottom: 1px dotted rgba(238, 240, 241, 1)"
          >
            <div class="pa-6" v-if="sendPaymentPayload">
              <small>to</small>
              <p class="AccountOwner py-2">
                {{ sendPaymentPayload.beneficiary.name }}
              </p>
              <p class="standardText">
                {{ sendPaymentPayload.beneficiary.account }} <br />
                {{ sendPaymentPayload.beneficiary.bank }}
              </p>
            </div>
          </div>
          <div
            class="paymentDetailsWrapper rounded-b-lg"
            style="border-bottom: 1px solid rgba(238, 240, 241, 1)"
          >
            <div class="pa-6">
              <small>you are sending</small>
              <p class="py-2" style="color: rgba(25, 40, 61, 0.8)">
                Enter the OTP sent to your email to approve this payment
              </p>
              <label>OTP</label>
              <v-text-field
                v-model="otp"
                outlined
                placeholder="0000"
                hide-details="auto"
                background-color="white"
              ></v-text-field>
              <div class="px-3">
                <p>
                  <span v-if="countdown > 0" class="waitText mb-0"
                    >wait for {{ countdown }} seconds to
                  </span>
                  <span
                    class="linkText mb-0 px-2"
                    @click.prevent="handleResendOtp"
                    >Resend OTP</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div
              class="
                py-5
                button__container
                d-flex
                align-center
                justify-space-between
              "
            >
              <v-btn
                @click="handleCancelSend"
                dark
                color="#F4F5F6"
                elevation="0"
                class="text-capitalize py-md-6 px-md-6"
              >
                <v-icon color="primary">mdi-chevron-left</v-icon>
                <span class="primary--text">Back</span>
              </v-btn>
              <v-btn
                :color="disablePost ? '#d9dee1' : 'primary'"
                class="text-capitalize py-md-6 px-md-6 ml-3"
                :loading="isSending"
                :disabled="disablePost"
                @click="handleComfirmOtp"
              >
                <v-icon> mdi-chevron-right </v-icon>
                <span class="ml-2">Confirm Send</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>
    </div>

    <addPayoutRecipient ref="AddRecipientDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addPayoutRecipient from "@/components/addPayoutRecipient.vue";
// Import the utility function
import { formatCurrencyAmount } from "@/mixins/amountFormatter.js";
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "SendOrReceiveMoney",
  data() {
    return {
      isLoadingDetails: false,
      isSending: false,
      isResendingOtp: false,
      countdown: 0,
      sendPaymentPayload: null,
      startMenu: false,
      endMenu: false,
      payableIncluded: false,
      otp: "",
      // TransactionDate: null,
      transactionFile: null,
      disablePost: false,
      sendMoney: {
        amount: (0).toFixed(2),
        narration: "",
        beneficiary: null,
        bank: null,
        from: "",
      },
      rules: {
        required: (v) => !!v || "This field is required",
      },
      selectedPayable: "",
      shouldUpdate: true,
    };
  },
  components: {
    addPayoutRecipient,
  },
  mixins: [currencyFilter],
  computed: {
    ...mapGetters({
      organizationBankAccounts: "organizations/getOrganizationBankAccounts",
      organizationInternalAccounts: "organizations/getAccountObjectForInternal",
      filteredAccountArray: "organizations/getAccountObject",
      combineCustAndVend: "contacts/getVendorAndCustomerCompanyName",
      organizationId: "auth/organizationId",
      organizationToken: "organizations/OrganToken",
      AllBanks: "transactions/getAllBanks",
      payoutRecipients: "transactions/getPayoutBeneficiaries",
      payablesArray: "transactions/getFormattedTransactions",
      // otp: "transactions/getOtp",
    }),

    // get the wallet balance
    getWalletBalance() {
      return this.$summary?.wallet;
    },
    formattedAmount() {
      return formatCurrencyAmount(this.sendMoney.amount);
    },
    formattedSendPayloadAmount() {
      return formatCurrencyAmount(this.sendPaymentPayload.amount);
    },

    // Computed property for amount field errors
    amountErrors() {
      const errors = [];
      if (this.rules.required(this.sendMoney.amount) !== true) {
        errors.push("This field is required");
      }
      if (
        this.selectedPayable &&
        parseFloat(this.sendMoney.amount) >
          parseFloat(this.selectedPayable.amount)
      ) {
        errors.push("Amount cannot exceed the selected payable amount");
      }
      return errors;
    },
  },
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    // method to close drawer
    handleCancelDrawer() {
      this.$emit("closeDrawer");
    },
    // method to go back to send payment form
    handleCancelSend() {
      this.sendPaymentPayload = null;
    },

    formatAmount() {
      this.sendMoney.amount = formatCurrencyAmount(this.sendMoney.amount);
      // Perform any additional processing if needed when the field loses focus
    },

    // open the add a bank modal
    async handleAddBankModal() {
      await this.$refs.AddBankDialog.fetchAllBanks();
      this.$refs.AddBankDialog.show(true);
    },

    triggerFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },

    async handleSendMoney() {
      if (this.$refs.sendReceive.validate()) {
        this.isSending = true;
        let payload = {
          type: "NIPTransfer",
          currency: this.$appCurrency,
          amount: this.sendMoney.amount.replace(/,/g, ""),
          description: this.sendMoney.narration,
          payable: this.selectedPayable.reference || "",
          journal: {
            to: "",
          },
          source: {
            wallet: this.walletID,
          },
          beneficiary: {
            id: this.sendMoney.beneficiary.id,
            type: this.sendMoney.beneficiary.type,
            hypn_id: this.sendMoney.beneficiary.hypn_id,
          },
        };

        // console.log(JSON.stringify(payload, null, 2))

        try {
          const data = await this.$store.dispatch("transactions/sendMoney", {
            organization_hypnID: this.orgHypnId,
            payload,
          });
          this.isLoadingDetails = true;
          this.sendPaymentPayload = data;
          setTimeout(() => {
            this.isLoadingDetails = false;
          }, 3000);

          this.showToast({
            sclass: "success",
            show: true,
            message: "Please check your mail for otp",
            timeout: 3000,
          });
          this.countdown = 120;
          // Start the countdown
          this.startCountdown();
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg ? error.msg : "An Error Occurred, try again",
            timeout: 3000,
          });
        } finally {
          this.isSending = false;
          // this.$refs.sendReceive.reset();
          // this.$emit("closeDrawer");
        }
      }
    },

    // send and comfirm otp
    async handleComfirmOtp() {
      const payload = {
        ref: this.sendPaymentPayload.ref,
      };
      try {
        await this.$store.dispatch("transactions/confirmTransactionMoney", {
          organization_hypnID: this.$orgHypnId,
          otp: this.otp,
          ref: payload,
        });

        this.showToast({
          sclass: "success",
          show: true,
          message: "otp confirmed",
          timeout: 3000,
        });
      } catch (error) {
        // console.log(JSON.stringify(error, null, 2));
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg ? error.msg : "An Error Occurred, try again",
          timeout: 3000,
        });
      }
    },

    // resend otp
    async handleResendOtp() {
      if (this.countdown > 0) {
        return;
      } else {
        try {
          await this.$store.dispatch(
            "transactions/resendOtp",
            this.sendPaymentPayload.ref
          );
          this.showToast({
            sclass: "success",
            show: true,
            message: "otp resent to mail",
            timeout: 3000,
          });
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg || "An error occurred",
            timeout: 3000,
          });
        } finally {
          this.countdown = 120;
          // Start the countdown
          this.startCountdown();
        }
      }
    },

    // countdown Timmer logic
    startCountdown() {
      // Decrease the countdown every second
      const intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          // Stop the countdown when it reaches 0
          clearInterval(intervalId);
        }
      }, 1000);
    },

    // open the add a bank modal
    async handleAddRecipient() {
      await this.$refs.AddRecipientDialog.fetchAllBanks();
      this.$refs.AddRecipientDialog.show(true);
    },
    // get wallet id
    async getWalletID() {
      try {
        await this.$store.dispatch("overview/getData", this.orgHypnId);
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      }
    },
  },
  mounted() {
    this.isLoadingDetails = true;
    setTimeout(() => {
      this.isLoadingDetails = false;
    }, 3000);
  },
  watch: {
    walletID: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          this.getWalletID(this.orgHypnId);
        }
      },
    },
    selectedPayable: {
      handler(val) {
        // Check the value of selectedPayable and update payableIncluded accordingly
        if (val) {
          this.payableIncluded = false;
          this.$refs.amountfield.validate();
        }
      },
      immediate: true, // Invoke the handler immediately with the current value
      deep: true, // Watch for changes in nested properties of selectedPayable
    },
    payableIncluded: {
      handler(val) {
        // Update selectedPayable based on payableIncluded value
        if (val) {
          this.selectedPayable = ""; // Set selectedPayable to null or an empty string
        }
      },
      immediate: true, // Invoke the handler immediately with the current value
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

a {
  color: #5b67ba;
}

.file-uploader {
  border-radius: 8px;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  margin: 20px 0;

  span {
    font-family: Inter;
    font-style: normal;

    //first child
    &:first-child {
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      color: #19283d;
    }

    //second child
    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(25, 40, 61, 0.8);
    }
  }

  button {
    text-decoration: underline;
    color: #5b67ba;
  }
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.drawer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.drawer_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.dottedLine {
  border: 1px dashed rgba(25, 40, 61, 0.5);
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

#clickArea {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.docTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.filename {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
  height: 100%;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.section_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.drawer__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}

.drawer__form {
  height: 100%;
}

.button__container {
  bottom: 10px;
}

.summaryContainer {
  border: 1px solid #d9dee1;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.summaryCardTitle {
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 12px;
  color: #596a73;
}

.walletBalance {
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter";
  line-height: 21.78px;
  color: #596a73;
}

.confirmAmount {
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter";
  line-height: 21.78px;
  color: #596a73;
}

small {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 12.1px;
  color: rgba(89, 106, 115, 1);
}

// .sectionHead {}

.paymentDetailsWrapper {
  // border: 1px solid rgba(238, 240, 241, 1);
  // border-bottom-style: dotted ;
  border-right: 1px solid rgba(238, 240, 241, 1);
  border-left: 1px solid rgba(238, 240, 241, 1);
  background-color: rgba(252, 252, 253, 1);
}

.AccountOwner {
  font-weight: 700;
  font-size: 18px;
  color: #465263;
  font-family: "Inter";
  line-height: 21.78px;
}

.standardText {
  font-weight: 400;
  font-size: 14px;
  color: #465263;
  font-family: "Inter";
  line-height: 21.78px;
}

.mainTxt {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--v-primary-base);
}

.subTxt {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 12.1px;
  color: rgba(89, 106, 115, 1);
}

.superTxt {
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 12.1px;
  color: rgba(89, 106, 115, 1);
}

.linkText {
  color: rgba(91, 103, 186, 1);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27.49px;
  text-decoration: underline;
  margin-bottom: 0px;
}

.waitText {
  color: var(--v-primary-base);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 27.49px;
  margin-bottom: 0px;
}
</style>
