<template>
  <div class="px-8 py-10">
    <div class="drawer__title">Reconcile</div>
    <div class="drawer__text pt-3">
      Configure your reconciliation workflow below. Selecting the fields you
      want to match and how you want to record the match.
    </div>
    <div class="pt-3">
      <v-form>
        <v-row>
          <v-col cols="12" class="">
            <v-text-field
              outlined
              :rules="nameRules"
              v-model="reconcilationName"
              label=""
              hide-details="auto"
              placeholder="Name this Reciliation"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="6" class="pt-1">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              bottom
              offset-y
              max-width="240px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  v-model="openDate"
                  :rules="[(v) => !!v || 'Value is required']"
                  v-bind="attrs"
                  outlined
                  v-on="on"
                  class="text__input px-0"
                  label="Date"
                  hint="*Opening Date"
                  persistent-hint
                >
                  <template>
                    <v-icon
                      small
                      class="input__icon ml-0"
                      slot="prepend-inner"
                      color="grey lighten-1"
                    >
                      mdi-calendar-month-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="openDate"
                hide-details="auto"
                outlined
                no-title
                @input="menu1 = false"
                color="primary"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pt-1">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              max-width="240px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  v-model="closeDate"
                  v-bind="attrs"
                  outlined
                  v-on="on"
                  class="text__input px-0"
                  label="Date"
                  hint="*Closing Date"
                  persistent-hint
                >
                  <template>
                    <v-icon
                      small
                      class="input__icon ml-0"
                      slot="prepend-inner"
                      color="grey lighten-1"
                    >
                      mdi-calendar-month-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="closeDate"
                hide-details="auto"
                outlined
                no-title
                @input="menu2 = false"
                color="primary"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="py-2" v-if="isPublished">
      <div class="published_info_wrapper d-flex align-center px-2 py-6">
        <img
          :src="require('@/assets/icons/info_x.png')"
          style="width: 28px; height: 28px"
        />
        <div class="piw__text pl-1">
          Reconciliation job completed,
          <span class="report"><a href="">see report</a></span>
        </div>
      </div>
    </div>
    <div class="pt-6">
      <ReconcileAccount
        optionTitle="This"
        :accountArray="accountArray"
        :color="thisColor"
        :isDisabled="isPublished"
      />
      <div class="d-flex justify-center">
        <v-timeline>
          <v-timeline-item fill-dot class="white--text" color="primary">
            <template v-slot:icon>
              <span>to</span>
            </template>
          </v-timeline-item>
        </v-timeline>
      </div>
      <ReconcileAccount
        optionTitle="That"
        :accountArray="accountArray"
        :color="thatColor"
        :isDisabled="isPublished"
      />
    </div>
    <ReconcileFlowGroup
      @update-type="conditions.properties.type = $event"
      :group-type="conditions.properties.type"
    >
      <div>
        <v-form>
          <v-row
            v-for="(selectInput, index) in selectInputs"
            :key="`selectInput-${index}`"
          >
            <v-col>
              <v-select
                outlined
                hide-details="auto"
                background-color="white"
                :items="conditionSelectionArray"
                v-model="selectInput.selectedValue"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex align-center justify-space-between py-3">
          <span class="addBtn px-2" @click="addField">+ add new field</span>
          <span
            class="removeBtn"
            @click="removeField(selectInputs)"
            v-if="selectInputs && selectInputs.length > 1"
            >- remove last field</span
          >
        </div>
        <div>
          <div class="drawer__title">Mark Transaction</div>
          <div v-for="(Transaction, index) in Transactions" :key="index">
            <v-switch
              hide-details="auto"
              class="mt-2"
              v-model="Transaction.value"
              :value="Transaction.value"
            >
              <template v-slot:label>
                <span class="switch__label">{{ Transaction.label }}</span>
              </template>
            </v-switch>
          </div>
        </div>
      </div>
    </ReconcileFlowGroup>
    <div class="d-flex justify-space-between pt-6">
      <v-btn
        color="#F4F5F6"
        @click="saveReconcile"
        elevation="0"
        :loading="isLoading"
        style="border-radius: 4px"
        class="text-capitalize px-md-8 py-md-6"
      >
        <img
          :src="require('@/assets/icons/white-btn-chevron.png')"
          alt=""
          class="mr-2"
          style="width: 6px; height: 12px"
        />
        Save
      </v-btn>
      <v-btn
        :color="publish_btn"
        class="px-md-8 py-md-6 text-capitalize"
        :loading="isLoading"
        :disabled="isPublished"
        style="border-radius: 4px"
        @click="publishReconcile"
      >
        <img
          :src="require('@/assets/icons/chevron-right.png')"
          alt=""
          class="mr-2"
          style="width: 6px; height: 12px"
        />
        <span class="btn__text">Publish</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ReconcileAccount from "../ReconcileDialogFlow/ReconcileAccount.vue";
import ReconcileFlowGroup from "../ReconcileDialogFlow/reconcileFlowGroup.vue";
export default {
  name: "reconcileNavigationDrawer",
  data() {
    return {
      reconcilationName: "",
      menu1: false,
      menu2: false,
      isLoading: false,
      isPublished: false,
      openDate: null,
      closeDate: null,
      dateFormatted: null,
      nameRules: [(v) => !!v || "Name is required"],
      accountArray: [],
      conditions: {
        type: "group",
        properties: {
          type: "and",
        },
      },
      selectInputs: [{ selectedValue: "Amount" }],
      conditionSelectionArray: ["Amount", "Reference"],
      Transactions: [
        { label: "Reconciled", value: false },
        { label: "Refund", value: false },
        { label: "Fees", value: false },
      ],
    };
  },

  computed: {
    thisColor() {
      if (this.isPublished) {
        return "#D9DEE1";
      } else return "#F2FCFA";
    },
    thatColor() {
      if (this.isPublished) {
        return "#D9DEE1";
      } else return "#FDFAF1";
    },
    publish_btn() {
      if (this.isPublished) {
        return "#D9DEE1";
      } else return "#19283D";
    },
  },
  components: { ReconcileAccount, ReconcileFlowGroup },

  methods: {
    addField() {
      this.selectInputs.push({ selectedValue: "Amount" });
    },
    removeField(fieldType) {
      let index = -1;
      fieldType.splice(index, 1);
    },

    saveReconcile() {
      console.log("save");
    },
    publishReconcile() {
      console.log("publish");
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.drawer__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.removeBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: red;
  cursor: pointer;
  border-bottom: 1px dashed #f77963;
}

.addBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #19283d;
  cursor: pointer;
  border-bottom: 1px dashed #96a9cf;
}

.switch__label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: var(--v-primary-base);
}

.save__btn {
  border-radius: 3.79141px;
}

.piw__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);

  .report a {
    text-decoration: underline;
    color: rgba(91, 103, 186, 1);
    font-weight: 600;
  }
}

.published_info_wrapper {
  background: #f6f3ee;
  border-radius: 8px;
}

.btn__text {
  color: white;
}
</style>
