<template>
  <div>
    <v-container class="px-md-0">
      <v-skeleton-loader type="table" :loading="isLoading">
        <v-layout
          row
          wrap
          class="align-center my-2 px-8"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-flex md3>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold text-center">
                Period
              </p>
            </div>
          </v-flex>
          <v-flex md4>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Accounts</p>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold text-center">
                Amount
              </p>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Difference</p>
            </div>
          </v-flex>

          <v-flex md1>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Report</p>
            </div>
          </v-flex>

          <!-- <v-flex md1>
            <div>
              <p class="mb-0 primary--text font-weight-bold">Status</p>
            </div>
          </v-flex> -->
        </v-layout>
        <div v-for="(row, index) in statementsData" :key="index">
          <reconcileDataTableRow
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="index"
            :dateFrom="row.dateFrom"
            :dateTo="row.dateTo"
            :accounts="row.accounts"
            :accountSettlement="row.accountSettlement"
            :reconciledAmount="row.reconciledAmount"
            :difference="row.difference"
            :report="row.report"
            :status="row.status"
          />
          <!-- only shows on mobile and tablets -->
          <ReconcileDataRowCard
            v-if="$vuetify.breakpoint.smAndDown"
            :dateFrom="row.dateFrom"
            :dateTo="row.dateTo"
            :accounts="row.accounts"
            :accountSettlement="row.accountSettlement"
            :reconciledAmount="row.reconciledAmount"
            :difference="row.difference"
            :report="row.report"
            :status="row.status"
          />
        </div>
      </v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import reconcileDataTableRow from "./reconcileDataTableRow.vue";
import ReconcileDataRowCard from "./reconcileDataRowCard.vue";

export default {
  name: "reconcileWithData",
  data() {
    return {
      isLoading: false,
      statementsData: [
        {
          dateFrom: "05/03/22",
          dateTo: "05/03/22",
          accounts: "Paystack (9026)",
          accountSettlement: "Paystack Settlement (9036)",
          reconciledAmount: "23,400,500.45",
          difference: "0",
          report: "Report Ready",
          status: "completed",
        },
        {
          dateFrom: "05/03/22",
          dateTo: "05/03/22",
          accounts: "Paystack (9026)",
          accountSettlement: "Paystack Settlement (9036)",
          reconciledAmount: "3,200,000.34",
          difference: "234,500",
          report: "-",
          status: "running",
        },
        {
          dateFrom: "05/03/22",
          dateTo: "05/03/22",
          accounts: "Paystack (9026)",
          accountSettlement: "Paystack Settlement (9036)",
          reconciledAmount: "14,400,500.87",
          difference: "-",
          report: "-",
          status: "saved",
        },
        {
          dateFrom: "05/03/22",
          dateTo: "05/03/22",
          accounts: "Paystack (9026)",
          accountSettlement: "Paystack Settlement (9036)",
          reconciledAmount: "7,400,500.00",
          difference: "23,450",
          report: "Report Ready",
          status: "completed",
        },
      ],
    };
  },
  components: { reconcileDataTableRow, ReconcileDataRowCard },
};
</script>

<style lang="scss" scoped></style>
