// csvDownloadMixin.js
import Papa from "papaparse";

export default {
  methods: {
    csvDownload(data, filename) {
      // Convert the data array to a CSV string using Papa.unparse
      const csvString = Papa.unparse(data);

      // Create a Blob from the CSV string
      const csvData = new Blob([csvString], {
        type: "text/csv;charset=utf-8;",
      });

      // Create a temporary anchor element to initiate the download
      const link = document.createElement("a");
      link.setAttribute("href", URL.createObjectURL(csvData));
      link.setAttribute("download", filename);
      document.body.appendChild(link); // Required for Firefox
      link.click();
      document.body.removeChild(link);
    },
  },
};
