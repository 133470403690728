<template>
  <div class="card mt-4 pl-10 pr-4 pt-6 pb-2 mt-md-16">
    <div class="card__row1">
      {{ cardTitle }}
    </div>
    <div class="card__row2 pt-1 pb-4" ref="amount">
      <span ref="amountValue" v-if="typeof amount === 'number'">{{
        formattedAmount | currency(currencyWithShortCode)
      }}</span>
      <span ref="amountValue" v-else>{{ formattedAmount }}</span>
    </div>

    <div class="d-flex align-self-end align-end">
      <div class="d-flex align-center card__row3 pb-1">
        {{ accountSource }}
        <p
          v-if="accountSource === 'banks'"
          class="ml-2 px-4 mb-0 rounded-pill"
          style="color: #e3aa1c; background-color: #fdf9ef"
        >
          coming soon
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
import currencyFilter from "@/mixins/currencyFilter";
export default {
  name: "transactionCards",
  props: {
    cardTitle: String,
    amount: {
      type: [String, Number],
    },
    accountSource: String,
  },
  data() {
    return {
      formattedAmount: "--",
      countUpInstance: null,
    };
  },
  mixins: [currencyFilter],
  mounted() {
    if (typeof this.amount === "number") {
      this.formattedAmount = this.amount;
      this.initializeCountUp();
    }
  },
  methods: {
    initializeCountUp() {
      this.countUpInstance = new CountUp(this.$refs.amountValue, this.amount, {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimalPlaces: 2,
        decimal: ".",
        prefix: "",
        suffix: "",
      });

      if (!this.countUpInstance.error) {
        this.countUpInstance.start();
      } else {
        console.error(this.countUpInstance.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
  border-radius: 4px;
  min-height: 132px;
  width: 100%;
  max-width: 630px;

  .card__row1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 35, 56, 0.5);
  }

  .card__row2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #19283d;
  }

  .card__row3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f96a1;
  }

  &:hover,
  &:active {
    background: #f6f3ee;
    border: 1px solid #d7a47b;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
    border-radius: 4px;
  }
}
</style>
