<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-form ref="payoutRecipient">
          <v-card-title
            style="background: #ffffff; border-radius: 8px 8px 0px 0px"
            class="px-md-6"
          >
            <img :src="require('@/assets/icons/tick-square.svg')" alt="" />
            <span class="modalTitle px-4">Add Payout Recipient</span>
            <v-spacer></v-spacer>
            <v-icon
              tag="button"
              @click="dialog = false"
              class="text-bolder"
              color="#596A73"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <div class="px-0 px-md-8 d-flex" style="background: #f3eee8">
            <v-tabs
              mobile-breakpoint="50"
              v-model="tab"
              background-color="#f3eee8"
              slider-size="4"
            >
              <v-tab v-for="item in addRecipientTab" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card style="background-color: #f8f7f4" flat>
                <v-form ref="payoutRecipient">
                  <div class="py-4 px-4 px-md-6">
                    <p class="py-3 ma-0 info__text">
                      Add a new payout recipient. A recipient is any business
                      (vendor) or individual (staff) you want to send a payment.
                    </p>

                    <div class="form__wrapper pa-8">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-label class="label" for="type-select"
                            >Type</v-label
                          >
                          <v-select
                            :rules="[rules.required]"
                            :items="recipientType"
                            item-text="text"
                            item-value="value"
                            background-color="#ffffff"
                            outlined
                            hide-details="auto"
                            placeholder="select one"
                            v-model="newRecipient.type"
                            id="type-select"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-label class="label" for="vendor-select"
                            >Recipient</v-label
                          >
                          <v-select
                            :rules="[rules.required]"
                            :items="filteredTeamMemberAndVendors"
                            item-value="id"
                            item-text="company"
                            background-color="#ffffff"
                            outlined
                            hide-details="auto"
                            placeholder="select one"
                            v-model="newRecipient.counterparty"
                            append-icon="mdi-magnify"
                            :menu-props="{ searchable: true, lazy: true }"
                            id="vendor-select"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-label class="label" for="bank-select"
                            >Bank</v-label
                          >
                          <v-select
                            :rules="[rules.required]"
                            :items="AllBanks"
                            item-text="bank"
                            item-value="code"
                            background-color="#ffffff"
                            outlined
                            hide-details="auto"
                            placeholder="select one"
                            v-model="newRecipient.bankcode"
                            append-icon="mdi-magnify"
                            :menu-props="{ searchable: true, lazy: true }"
                            id="bank-select"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-label class="label" for="account-number"
                            >Account Number</v-label
                          >
                          <v-text-field
                            outlined
                            v-model="newRecipient.accountnumber"
                            hide-details="auto"
                            background-color="#ffffff"
                            :rules="[
                              rules.required,
                              rules.characterLength,
                              rules.digits,
                            ]"
                            @blur="confirmBankAccount"
                          >
                          </v-text-field>
                          <small
                            v-if="
                              confirmedBankName &&
                              confirmedBankName !==
                                'unable to confirm recipient account, try again'
                            "
                            >Name:
                            <span class="info__smalltext">{{
                              confirmedBankName
                            }}</span></small
                          >
                          <small v-else
                            ><span class="info__errortext">{{
                              confirmedBankName
                            }}</span></small
                          >
                        </v-col>
                      </v-row>
                    </div>

                    <div class="d-flex justify-center justify-md-end py-2">
                      <v-btn
                        @click="dialog = false"
                        dark
                        color="#F4F5F6"
                        elevation="0"
                        class="d-flex text-capitalize mt-4 mb-10 mr-5 px-4 py-6"
                      >
                        <v-icon small color="primary">mdi-close</v-icon>
                        <span class="primary--text">Cancel</span>
                      </v-btn>
                      <v-btn
                        link
                        @click="addRecipient"
                        dark
                        :loading="isAdding"
                        color="primary"
                        class="d-flex text-capitalize mt-4 mb-10 px-4 py-6"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                        <span class="">Add Recipient</span>
                      </v-btn>
                    </div>
                  </div>
                </v-form>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      isAdding: false,
      tab: null,
      addRecipientTab: ["Payout Recipient"],
      confirmedBankName: null,
      bankAccountConfirmed: false,
      selectedBank: null,
      recipientType: [
        {
          text: "Vendor",
          value: "vendor",
        },
        {
          text: "Staff",
          value: "staff",
        },
      ],
      newRecipient: {
        accountnumber: "",
        bankcode: "",
        type: "",
        counterparty: "",
      },

      rules: {
        required: (v) => !!v || "This field is required",
        characterLength: (v) =>
          (v && v.length === 10) || "Account number must be exactly 10 digits",
        digits: (v) => /^\d+$/.test(v) || "Only numbers are allowed",
      },
    };
  },

  computed: {
    ...mapGetters({
      AllBanks: "transactions/getAllBanks",
      organizationId: "auth/organizationId",
      counterParty: "contacts/getVendorAndTeamName",
    }),

    // Computed property to filter customers and vendors based on the selected recipient type
    filteredTeamMemberAndVendors() {
      const selectedType = this.newRecipient.type;
      return this.counterParty
        ?.filter((item) => {
          if (selectedType === "vendor") {
            return item.entity_type === "vendor";
          } else if (selectedType === "staff") {
            return item.entity_type === "staff";
          }
          return false; // Return false for other cases or unhandled types
        })
        .sort((a, b) => a.company.localeCompare(b.company));
    },
  },
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    show(value) {
      this.dialog = value;
    },
    async addRecipient() {
      if (this.$refs.payoutRecipient.validate()) {
        if (this.bankAccountConfirmed) {
          this.isAdding = true;
          try {
            let sendLoad = {
              organization_hypnID: this.orgHypnId,
              payload: this.newRecipient,
            };

            await this.$store.dispatch(
              "transactions/CreatePayoutBeneficiaries",
              sendLoad
            );

            // Display a success toast message if the dispatch is successful
            this.showToast({
              sclass: "success",
              show: true,
              message: "Recipient Added successfully!",
              timeout: 3000,
            });

            // Fetch payout beneficiaries only if the previous actions were successful
            await this.$store.dispatch(
              "transactions/getPayoutBeneficiaries",
              this.orgHypnId
            );

            this.$refs.payoutRecipient.reset();
            this.confirmedBankName = null;
            this.bankAccountConfirmed = false;

            // Close the dialog
            this.dialog = false;
          } catch (error) {
            console.error(error);
            this.showToast({
              sclass: "error",
              show: true,
              message: error.msg || "Unable to add recipient",
              timeout: 3000,
            });
          } finally {
            this.isAdding = false;
          }
        }
      }
    },

    async fetchAllBanks() {
      try {
        await this.$store.dispatch("transactions/getAllBankNamesAndCodes");
      } catch (error) {
        console.log(error);
      }
    },

    async confirmBankAccount() {
      if (this.$refs.payoutRecipient.validate()) {
        try {
          let sendLoad = {
            nip_code: this.newRecipient.bankcode,
            accountnumber: this.newRecipient.accountnumber,
          };
          const data = await this.$store.dispatch(
            "transactions/confirmAccount",
            sendLoad
          );
          if (data.statusCode == "200") {
            this.confirmedBankName = data.data.accountName;
            this.bankAccountConfirmed = true;
          }
        } catch (error) {
          console.log(error);
          this.confirmedBankName =
            "unable to confirm recipient account, try again";
          this.bankAccountConfirmed = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.45px;
  color: rgba(25, 40, 61, 0.8);
}

.info__smalltext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.45px;
  color: rgba(91, 103, 186, 1);
  /* 408ed5 */
}

.info__errortext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.45px;
  color: #ea0808;
  /* 408ed5 */
}

.mono_link {
  color: #5b67ba;
  text-decoration: underline;
  cursor: pointer;
}

.form__wrapper {
  background: #ffffff;
  border: 1px solid #d9dee1;
  border-radius: 4px;
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
