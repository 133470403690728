<template>
  <div class="card mx-1 px-2">
    <div class="d-flex align-center justify-space-around">
      <v-chip color="#EEFCF9" text-color="#2BD5AE" x-small class="chip__text">{{
        accounts
      }}</v-chip>
      <v-chip color="#FDF9EF" text-color="#E3AA1C" x-small class="chip__text">{{
        accountSettlement
      }}</v-chip>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div class="d-flex align-center">
        <span class="mb-0 row__text">{{ difference }}</span>
      </div>
      <div class="d-flex align-center">
        <span class="mb-0 text-truncate link__text"
          ><a href="#" download>{{ report }}</a></span
        >
      </div>
      <!-- <div class="d-flex align-center justify-start">
        <span class="mb-0 row__text" :style="{ color: statusIcon }">{{
          status
        }}</span>
      </div> -->
    </div>
    <div class="d-flex align-center justify-space-around">
      <div class="d-flex align-center">
        <span class="mb-0 amount__text">{{ reconciledAmount }}</span>
      </div>
      <div class="d-flex align-center">
        <span class="mb-0 text-center row__text" style=""
          >{{ dateFrom }} - {{ dateTo }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reconcileDataTableRowCard",

  props: {
    dateFrom: {
      type: String,
    },
    dateTo: {
      type: String,
    },
    index: {
      type: Number,
    },
    accounts: {
      type: String,
    },

    accountSettlement: {
      type: String,
    },
    reconciledAmount: {
      type: String,
    },
    difference: {
      type: [String, Number],
      default: 0,
    },

    status: {
      type: String,
    },

    report: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    statusIcon() {
      if (this.status == "saved") {
        return "#7F919B";
      } else if (this.status == "running") {
        return "#E3AA1C";
      } else {
        return "#2BD5AE";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 200px;
  display: grid;
  grid-gap: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .contact_row {
    width: 100%;
    font-weight: 600;
  }
}

.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
}

.amount__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary);
}
</style>
