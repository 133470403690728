<template>
  <div class="drawer__container">
    <div class="loader" v-if="isLoadingDetails">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="px-2 px-sm-6 px-md-8 py-8 drawer__form" v-else>
      <div>
        <p class="drawer_title">Bank Statement</p>
        <p class="drawer_info py-2">
          Visit your banking portal and download your bank statement and convert
          to CSV format. Your CSV must follow this
          <a
            href="https://docs.google.com/spreadsheets/d/1H_3LNDJW7ObDKEUcFegJpN3UhKmE-vvKCrh7bYgk_6w/edit#gid="
            target="_blank"
            >template</a
          >
          (opens in Excel/Google Sheet)
        </p>
      </div>
      <div class="py-1">
        <label>Select Bank</label>
        <v-select
          :items="AllBanks"
          item-text="bank"
          item-value="code"
          :menu-props="{ searchable: true, lazy: true }"
          prepend-inner-icon="mdi-magnify"
          :hide-details="true"
          outlined
          dense
          v-model="bankStateObject.bank"
          class="hypn-input"
        >
        </v-select>
      </div>

      <v-row>
        <v-col cols="6">
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="true"
            transition="scale-transition"
            bottom
            offset-overflow
            min-width="auto"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                background-color="white"
                v-model="bankStateObject.startDate"
                hide-details="auto"
                :rules="[(v) => !!v || 'Value is required']"
                v-bind="attrs"
                outlined
                v-on="on"
                class="text__input px-0 hypn-input"
                persistent-hint
                style="margin-top: 23px"
                dense
              >
                <template>
                  <v-icon
                    small
                    class="input__icon ml-0"
                    slot="prepend-inner"
                    color="grey lighten-1"
                  >
                    mdi-calendar-month-outline
                  </v-icon>
                </template>
                <template #label> Start Date </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="bankStateObject.startDate"
              :hide-details="true"
              outlined
              no-title
              @input="startMenu = false"
              color="primary"
              :max="getTodayDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6">
          <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="true"
            transition="scale-transition"
            bottom
            offset-overflow
            min-width="auto"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                background-color="white"
                v-model="bankStateObject.endDate"
                hide-details="auto"
                :rules="[(v) => !!v || 'Value is required']"
                v-bind="attrs"
                outlined
                v-on="on"
                class="text__input px-0 hypn-input"
                persistent-hint
                style="margin-top: 23px"
                dense
              >
                <template>
                  <v-icon
                    small
                    class="input__icon ml-0"
                    slot="prepend-inner"
                    color="grey lighten-1"
                  >
                    mdi-calendar-month-outline
                  </v-icon>
                </template>
                <template #label> End Date </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="bankStateObject.endDate"
              :hide-details="true"
              outlined
              no-title
              @input="endMenu = false"
              color="primary"
              :max="getTodayDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <div class="pt-3 pb-1">
        <label>Opening Balance</label>
        <v-text-field
          v-model="bankStateObject.amount"
          hide-details="auto"
          outlined
          dense
          placeholder="0.00"
          @blur="formatAmount"
        >
        </v-text-field>
      </div>

      <div class="file-uploader">
        <span>Upload Statement</span>
        <span>Upload your statement in the template format above</span>
        <button>upload</button>
      </div>

      <div
        class="py-5 button__container d-flex align-center justify-space-between"
      >
        <v-btn
          @click="handleCancelDrawer"
          dark
          color="#F4F5F6"
          elevation="0"
          class="text-capitalize py-md-6 px-md-6"
        >
          <v-icon color="primary">mdi-close</v-icon>
          <span class="primary--text">Cancel</span>
        </v-btn>
        <v-btn
          :color="disablePost ? '#d9dee1' : 'primary'"
          class="text-capitalize py-md-6 px-md-6 ml-auto"
          :disabled="disablePost"
          @click="handleBankStatement"
        >
          <v-icon> mdi-chevron-right </v-icon>
          <span class="ml-2">Upload</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// Import the utility function
import { formatCurrencyAmount } from "@/mixins/amountFormatter.js";
export default {
  name: "BankStatementUpload",
  data() {
    return {
      isLoadingDetails: false,

      startMenu: false,
      endMenu: false,
      // TransactionDate: null,
      transactionFile: null,
      disablePost: false,
      bankStateObject: {
        bank: "",

        amount: 0,
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      organizationBankAccounts: "organizations/getOrganizationBankAccounts",
      organizationInternalAccounts: "organizations/getAccountObjectForInternal",
      filteredAccountArray: "organizations/getAccountObject",
      combineCustAndVend: "contacts/getVendorAndCustomerCompanyName",
      organizationId: "auth/organizationId",
      organizationToken: "organizations/OrganToken",
      AllBanks: "transactions/getAllBanks",
    }),
    // // filter the combineCustAndVend array using the search value..
    filteredCustomersAndVendors() {
      let unSorted = this.combineCustAndVend
        ?.filter((item) => item.company !== null)
        .sort();
      return unSorted.sort((a, b) => a.company.localeCompare(b.company));
    },

    formattedAmount() {
      return formatCurrencyAmount(this.bankStateObject.amount);
    },
  },
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    // method to close drawer
    handleCancelDrawer() {
      this.$emit("closeDrawer");
    },
    formatAmount() {
      this.bankStateObject.amount = formatCurrencyAmount(
        this.bankStateObject.amount
      );
      // Perform any additional processing if needed when the field loses focus
    },

    // set max date
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    triggerFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    async handleBankStatement() {},
  },
  mounted() {
    this.isLoadingDetails = true;
    setTimeout(() => {
      this.isLoadingDetails = false;
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

a {
  color: #5b67ba;
}

.file-uploader {
  border-radius: 8px;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  margin: 20px 0;

  span {
    font-family: Inter;
    font-style: normal;

    //first child
    &:first-child {
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      color: #19283d;
    }

    //second child
    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(25, 40, 61, 0.8);
    }
  }

  button {
    text-decoration: underline;
    color: #5b67ba;
  }
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.drawer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.drawer_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.dottedLine {
  border: 1px dashed rgba(25, 40, 61, 0.5);
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

#clickArea {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.docTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.filename {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
  height: 100%;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.section_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.drawer__container {
  height: 100vh;
}

.drawer__form {
  height: 100%;
}

.button__container {
  bottom: 10px;
}
</style>
