<template>
  <div class="detail-drawer" v-if="accountBalance">
    <div class="d-flex justify-space-between align-center">
      <p class="title pb-2">Balance Details</p>
      <v-btn @click="close" icon color="primary">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="section__div">
      <p class="headerText mb-0">Account Name</p>
      <p class="valueText">{{ accountBalance.account_name }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Current Balance</p>
      <p class="amountText">
        {{ accountBalance.current_balance | currency() }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Code</p>
      <p class="valueText">{{ accountBalance.code }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Ledger</p>
      <p class="valueText">{{ accountBalance.ledger }}</p>
    </div>
    <!-- <div class="section__div">
      <p class="headerText">Items</p>
      <div :class="{ 'item-container': singlePayment.items.length > 1 }">
        <div
          v-for="(item, index) in singlePayment.items"
          :key="index"
          class="itemCard"
        >
          <div
            v-for="(value, key) in item"
            :key="key"
            class="d-flex align-center justify-content-md-evenly"
          >
            <span class="itemText">{{ key }}:</span>
            <span class="itemTextLight mx-4">{{ value }}</span>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="section__div mt-2">
      <p class="headerText mb-0">Contact</p>
      <div class="d-flex">
        <span class="valueText">Name: </span>
        <span class="valueTextLight ml-4 mr-1">{{
          singlePayment.contact.firstname
        }}</span>
        <span class="valueTextLight"
          >{{ singlePayment.contact.lastname }}
        </span>
      </div>
      <div class="d-flex">
        <span class="valueText">Business: </span
        ><span class="valueTextLight mx-4"
          >{{ singlePayment.contact.business }}
        </span>
      </div>
      <div class="d-flex">
        <span class="valueText">Email: </span
        ><span class="valueTextLight mx-4"
          >{{ singlePayment.contact.email }}
        </span>
      </div>
      <div class="d-flex">
        <span class="valueText">Phone: </span
        ><span class="valueTextLight mx-4"
          >{{ singlePayment.contact.phone }}
        </span>
      </div>
    </div> -->

    <!-- <div class="section__div" v-if="singlePayment.receipt">
      <p class="headerText">Receipt ID</p>
      <p @click="handleDownloadReceipt" class="linkText">
        {{ singlePayment.receipt }}
      </p>
    </div> -->
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter.js";
import { mapGetters } from "vuex";
export default {
  name: "AccountBalanceDetails",

  mixins: [currencyFilter],
  methods: {
    // close drawer
    close() {
      this.$emit("close");
    },

    // handle receipt download
    handleDownloadReceipt() {
      console.log(this.singlePayment.receipt);
      if (!this.singlePayment.receipt) {
        return;
      }

      // Use Vue Router to navigate to the new route in a new window
      const router = this.$router;
      const newWindow = window.open("", "_blank");

      if (newWindow) {
        // Construct the URL of the route with the paymentId parameter
        const routeParams = { paymentId: this.singlePayment.receipt };
        const routeOptions = {
          name: "transactionReceipt",
          params: routeParams,
        };

        // Resolve the route URL and set it in the new window's location
        newWindow.location.href = router.resolve(routeOptions).href;
      } else {
        // Handle cases where the browser blocks pop-up windows
        alert("Pop-up window blocked. Please allow pop-ups for this site.");
      }
    },
  },
  computed: {
    //vuex getters
    ...mapGetters({
      // organizationToken: "organizations/OrganToken",
      accountBalance: "transactions/getAccountBalance",
    }),
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.detail-drawer {
  // overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 40px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .section__div {
    padding-bottom: 4px;

    .headerText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #8f96a1;
      display: block;
    }

    .valueText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .valueTextLight {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .itemText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: flex;
    }

    .itemTextLight {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: flex;
    }

    .amountText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .linkText {
      color: rgba(91, 103, 186, 1);
      cursor: pointer;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 27.49px;
      text-decoration: underline;
      margin-bottom: 0px;
    }
  }
}

.content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: repeat(2, minmax(0, 1fr));
  // flex: 1;
  // overflow: auto;
  // margin-top: 5px;
  // &__action {
  //   padding: 0px 5px;
  //   cursor: pointer;
  //   margin: 20px 0px;
  //   &:hover {
  //     span {
  //       color: #d7a47b;
  //     }
  //   }
  //   img {
  //     width: 30px;
  //     height: 30px;
  //     display: block;
  //     margin: auto;
  //   }
  //   span {
  //     display: block;
  //     text-align: center;
  //     margin-top: 10px;
  //     color: #7f919b;
  //   }
  //   .coming-soon {
  //     font-family: "Inter";
  //     opacity: 0.9;
  //     margin-top: 1px;
  //     color: #d7a47b;
  //   }
  // }

  .card {
    background: #f8f9fc;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 20px;

    &__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
    }

    &__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      color: #8f96a1;
      opacity: 0.8;
    }
  }
}

.item-container {
  height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}

.itemCard {
  border-radius: 8px;
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 15px;
}
</style>
