<template>
  <div :style="{ backgroundColor: color }" class="container__wrapper px-5 py-3">
    <div class="optionTitle">{{ optionTitle }}</div>
    <v-form>
      <v-select
        :disabled="isDisabled"
        outlined
        placeholder="Select an Account"
        background-color="#ffffff"
        :items="accountArray"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "ReconcileAccount",
  props: {
    optionTitle: {
      type: String,
      required: true,
    },
    accountArray: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.container__wrapper {
  border-radius: 8px;
}

.optionTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: rgba(25, 40, 61, 0.8);
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 700;
  text-transform: capitalize;
  color: #596a73;
}
</style>
