<template>
  <div class="d-flex align-center card-container px-3">
    <v-avatar
      :color="avatarColor"
      class="card__avatar mr-2"
      style=""
      size="56"
      >{{ number }}</v-avatar
    >
    <div class="card__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "reconcileSteps",
  props: {
    avatarColor: { type: String },
    number: { type: String },
    text: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  height: 100px;
  background: #f4f5f6;
  border: 1px solid #d9dee1;
  border-radius: 8px;
}

.card__avatar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.card__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #19283d;
  max-width: 200px;
}

@media screen and (min-width: 1224px) {
  .card-container {
    width: 200px;
    height: 100px;
  }

  .card__text {
    max-width: 100%;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .card-container {
    width: 330px;
    height: 100px;
  }

  .card__text {
    max-width: 100%;
  }
}
</style>
