var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-6 py-6 my-1",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center px-4",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center bold__text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.balance,_vm.currencyNoSymbol)))])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center row__text text-truncate"},[_vm._v(_vm._s(_vm.account))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"pa-1 text-center",attrs:{"x-small":"","text-color":"#3B4E62","dark":"","color":_vm.statusColor}},[_c('span',{staticClass:"px-2 py-1 ma-0 text-uppercase font-weight-bold",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.type))])])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 row__text text-truncate"},[_vm._v(_vm._s(_vm.accountName)+" "),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.accountNumber))])])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-truncate row__text"},[_vm._v(_vm._s(_vm.lastUpdated))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$emit('view-Balance')}}},[_vm._v(" Get Balance ")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }